export const CUSTOM_ICONS = [
  '/assets/icons/edit.svg',

  '/assets/icons/social/facebook.svg',
  '/assets/icons/social/linkedin.svg',
  '/assets/icons/social/instagram.svg',

  '/assets/icons/auth/auth_facebook.svg',
  '/assets/icons/auth/auth_google.svg',
  '/assets/icons/auth/auth_edulog.svg',

  '/assets/icons/schools/address_private.svg',
  '/assets/icons/schools/address_public.svg',
  '/assets/icons/schools/address_special.svg',

  '/assets/icons/schools/coop-ph_private.svg',
  '/assets/icons/schools/coop-ph_public.svg',
  '/assets/icons/schools/coop-ph_special.svg',

  '/assets/icons/schools/location_private.svg',
  '/assets/icons/schools/location_public.svg',
  '/assets/icons/schools/location_special.svg',

  '/assets/icons/schools/students_private.svg',
  '/assets/icons/schools/students_public.svg',
  '/assets/icons/schools/students_special.svg',

  '/assets/icons/schools/teachers_private.svg',
  '/assets/icons/schools/teachers_public.svg',
  '/assets/icons/schools/teachers_special.svg',

  '/assets/icons/schools/website_private.svg',
  '/assets/icons/schools/website_public.svg',
  '/assets/icons/schools/website_special.svg',

  '/assets/icons/schools/schuleinheit.svg',

  '/assets/icons/my-schools/edit-admin.svg',
  '/assets/icons/my-schools/edit-locations.svg',
  '/assets/icons/my-schools/hr-tool.svg',
  '/assets/icons/my-schools/edit-school.svg',
];

export function getIconName(icon: string) {
  const s = icon.split('/');
  return s[s.length - 1].replace('.svg', '');
}
